
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const submitButton = ref<HTMLButtonElement | null>(null);
    const toggleVisibility = ref<boolean>(true);

    //Create form validation object
    const login = Yup.object().shape({
      login: Yup.string()
        .email("Le format d'email n'est pas bon")
        .required("L'adresse email est requise")
        .max(254, "Le ${label} ne doit pas dépasser ${max} caractères"),
      password: Yup.string().min(4).required("Le mot de passe est requis"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      values.login = values.login.toLowerCase();
      store.commit(Mutations.PURGE_AUTH);
      // Send login request
      values.remember_me = values.remember_me === "1";
      await store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          const query = route.query;
          if (query.returnUrl) {
            window.location.replace(query.returnUrl as string);
          } else {
            if (store.getters.userBackofficeAccess) {
              router.push({ name: "back-office" });
            } else {
              router.push({ name: "homepage" });
            }
          }
        })
        .catch(() => {
          router.push({ name: "sign-in" });
        });
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      toggleVisibility,
    };
  },
});
